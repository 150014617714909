@import (reference) "./variables";

.table {
    width: 100%;

    thead {
        tr {
            .section-banner;

            th {
                .font-size-s;
                padding: 4px 0 5px;
                font-weight: normal;
                text-align: left;
                text-transform: uppercase;

                &.text-center {
                    .text-center;
                }
            }
        }
    }

    tbody {
        tr {
            border-radius: 0.3rem;

            &:nth-child(even) {
                background: linear-gradient(
                    to bottom,
                    @color-bg-1,
                    @color-bg-3
                );
            }

            &.selected {
                box-shadow: 0 0 10px 0 @color-high-1;
                background-image: linear-gradient(
                    to right,
                    @color-high-2,
                    @color-high-1
                );
            }

            &:hover:not(.selected) {
                cursor: pointer;
                background: @color-bg-2;
                box-shadow: none;
            }

            td {
                border-bottom: solid 0.1rem transparent;
                border-top: solid 0.1rem transparent;
            }

            td:last-child {
                border-right: solid 0.1rem transparent;
            }

            td:first-child {
                border-left: solid 0.1rem transparent;
            }

            .price-cell {
                .text-right;
                padding-right: 5px;
                width: 50px;
            }
        }
    }
}

.amountDelta {
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-play-state: running;
}

.amountDeltaUp {
    .amountDelta;
    animation-name: amountDeltaUpAnimation;
}

.amountDeltaDown {
    .amountDelta;
    animation-name: amountDeltaDownAnimation;
}

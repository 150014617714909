@import (reference) "resources/styles/variables";
@import (reference) "resources/styles/form";

.asset-upload-form {
    margin: 20px;

    input,
    select,
    textarea {
        width: 100%;
        color: inherit;
        background-color: transparent;
        outline: none;
        border: none;
        border-radius: 0;
    }

    input {
        padding: 0.5em 0;
        border-bottom: 1px solid @color-bg-1;

        &:focus {
            border-bottom: 1px solid @color-high-1;
        }
    }

    textarea {
        height: 330px;

        padding: 0.5em;
        border: 1px solid @color-bg-1;

        &:focus {
            border: 1px solid @color-high-1;
        }
    }

    button {
        .button;
    }

    label {
        display: block;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .error-banner {
        border-color: 1px solid @color-danger;
        background-color: @color-danger;
        padding: 20px;
        margin-bottom: 10px;
    }

    select > option {
        background-color: @color-bg-1;
    }
}

@import (reference) "../../../resources/styles/variables";

.compositors-banner {
    .pure-menu-horizontal {
        width: auto;
        padding: 0;
    }

    .pure-menu-list {
        text-align: left;

        .pure-menu-item.pure-menu-has-children {
            vertical-align: inherit;

            .pure-menu-link {
                color: @white;
                cursor: pointer;
                padding: 4px 10px 5px;

                &:hover {
                    background-color: @color-bg-1;
                }
            }

            &.pure-menu-active {
                > .pure-menu-link {
                    background-color: @color-bg-1;
                }
            }

            .pure-menu-children {
                width: 100%;

                .pure-menu-item {
                    background-color: @color-bg-1;

                    .pure-menu-link {
                        color: @white;
                    }

                    &:hover,
                    .pure-menu-link:hover {
                        color: @white;
                        background-color: @color-high-1;
                    }
                }
            }
        }
    }

    .tenant-selection-menu.pure-menu-link {
        min-width: 200px;
    }
}

@import (reference) "../../../../resources/styles/variables";

@logo-size: 60px;
@pulse-time: 1.5s;

.race-title-bar {
    display: flex;
    justify-content: center;

    .logo {
        .center-center;
        cursor: pointer;
        width: @logo-size;
        height: @logo-size;

        img {
            width: @logo-size;
            height: @logo-size;
        }

        &.no-logo {
            border: 1px solid @color-text-secondary;
            transition: all 0.2s ease-out;

            &:hover {
                color: @color-high-1;
                border: 1px solid @color-high-1;
            }
        }
    }

    .race-card {
        width: @logo-size;
        height: @logo-size;
        color: @color-text-primary;
        background-color: @color-high-1;
        text-align: center;
        padding: 4px 0 7px;
        margin-right: 8px;
    }

    .race-details {
    }

    h4 {
        font-size: @font-size-l;
    }

    h5 {
        font-size: @font-size-s;
        color: @color-high-1;
    }

    h6 {
        font-size: @font-size-xxs;
    }

    h4,
    h5,
    h6 {
        font-weight: normal;
        margin: 0;
    }
}

@import (reference) "../../../../resources/styles/variables";

@logo-size: 60px;
@pulse-time: 1.5s;

.race-title-bar {
    display: flex;
    justify-content: center;

    .logo {
        .center-center;
        cursor: pointer;
        width: @logo-size;
        height: @logo-size;

        img {
            width: @logo-size;
            height: @logo-size;
        }

        &.no-logo {
            border: 1px solid @color-text-secondary;
            transition: all 0.2s ease-out;

            &:hover {
                color: @color-high-1;
                border: 1px solid @color-high-1;
            }
        }
    }

    .race-card {
        width: @logo-size;
        height: @logo-size;
        color: @color-text-primary;
        background-color: @color-high-1;
        text-align: center;
        padding: 4px 0 7px;
        margin-right: 8px;
    }

    .race-details {
    }

    h4 {
        font-size: @font-size-l;
    }

    h5 {
        font-size: @font-size-s;
        color: @color-high-1;
    }

    h6 {
        font-size: @font-size-xxs;
    }

    h4,
    h5,
    h6 {
        font-weight: normal;
        margin: 0;
    }

    .edit-button {
        border: none;
        background: transparent;
        border: 1px solid @color-high-1;
        padding: 5px;
        margin: 0 10px;
    }

    .edit-box {
        width: 500px;
        background: transparent;
        border: none;
        border-bottom: 2px solid @color-high-1;
    }
}

.heartbeats {
    .center-center;
    padding: 0 30px;

    .heartbeat-container {
        position: relative;

        .pulse {
            position: absolute;
            top: 0;
            left: 5px;
            border: 1px solid transparent;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            z-index: 100;

            background-color: transparent;
            animation: pulsate @pulse-time linear;
            animation-iteration-count: 1;
        }

        @-webkit-keyframes pulsate {
            0% {
                -webkit-transform: scale(1, 1);
                opacity: 0;
            }
            50% {
                opacity: 1;
            }
            100% {
                -webkit-transform: scale(1.5, 1.5);
                opacity: 0;
            }
        }

        .circle {
            width: 20px;
            height: 20px;
            border-radius: 10px;
            margin: 0 5px;
            cursor: pointer;
        }

        .heartbeat-state(@color) {
            .circle {
                background-color: @color;
            }

            .pulse {
                border-color: @color;
            }
        }

        &.normal {
            .heartbeat-state(#008543);
        }

        &.warning {
            .heartbeat-state(#dd6);
        }

        &.error {
            .heartbeat-state(#f66);
        }

        &.fatal {
            .heartbeat-state(#980013);
        }

        .tooltip {
            position: absolute;
            top: 80%;
            left: 80%;
            width: max-content;

            padding: 10px;

            background-color: fade(@color-bg-1, 60%);

            display: none;

            p {
                margin: 0;
            }
        }

        &:hover .tooltip {
            display: initial;
        }
    }
}

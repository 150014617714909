@import (reference) "./colours.less";

@color-bg-0: @charcoal-grey;
@color-bg-1: @slate-grey;
@color-bg-2: #86879c;
@color-bg-3: #3e4058;

@color-high-1: @cerulean;
@color-high-2: @denim-blue;
@color-text-primary: @white;
@color-text-secondary: @grey-1;

@color-ok: #008543;
@color-danger: #980013;
@color-disabled: @grey-1;

@color-interim: #e34c3d;
@color-final: #00ff87;
@color-final-dark: darken(#00ff87, 30%);

@font-size-max: 38px;
@font-size-xxl: 32px;
@font-size-xl: 26px;
@font-size-l: 24px;
@font-size-m: 22px;
@font-size-s: 20px;
@font-size-xs: 18px;
@font-size-xxs: 14px;

.font-size-max {
    font-size: @font-size-max;
}
.font-size-xxl {
    font-size: @font-size-xxl;
}
.font-size-xl {
    font-size: @font-size-xl;
}
.font-size-l {
    font-size: @font-size-l;
}
.font-size-m {
    font-size: @font-size-m;
}
.font-size-s {
    font-size: @font-size-s;
}
.font-size-xs {
    font-size: @font-size-xs;
}
.font-size-xxs {
    font-size: @font-size-xxs;
}

//////////////////// HELPER STYLES

.section-banner {
    .font-size-s;
    font-weight: normal;
    background-color: @color-bg-1;
    color: @color-text-primary;
    text-transform: uppercase;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.center-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@import (reference) "../../resources/styles/variables";

@border-width: 1px;
@height: 60px;

.form-button {
    .font-size-s;
    .center-center;
    align-items: stretch;
    border: @border-width solid @color-high-1;
    height: @height;
    text-transform: uppercase;
    cursor: pointer;
    background-color: transparent;
    width: 200px;

    &.danger {
        border: @border-width solid @color-danger;
        outline-color: @color-danger;

        &.confirming {
            background-color: @color-danger;
        }
    }

    &.disabled {
        cursor: default;
        color: @color-text-secondary;
        border-color: @color-text-secondary;
    }

    > .active:not(.disabled) {
        box-shadow: 0 0 10px 0 @color-high-1;
        background-color: @color-high-1;

        + .options-button.active {
            border-left: 1px solid @color-text-primary;
        }
    }

    &:focus {
        background-color: @color-high-1;
    }

    &.danger:focus {
        background-color: @color-danger;
    }

    .title {
        .center-center;
        flex: 1 1 0;
        transition: background-color 0.2s ease-in-out;
    }
}

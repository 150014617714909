@import (reference) "resources/styles/variables";

.asset-management-page {
    display: flex;
    margin: 20px;

    .asset-type-column {
        display: flex;
        flex-direction: column;

        .module {
            margin: 0px;
            display: flex;
            flex-direction: column;
            border-bottom: 1px grey solid;
            padding: 15px 0;

            .title {
                cursor: default;
                padding: 5px 10px 10px;
                text-align: center;
                font-size: @font-size-s;
            }
        }

        .module:last-child {
            border-bottom: none;
        }

        a {
            padding: 10px;
            cursor: pointer;
        }

        a.active {
            background-color: white;
            color: black;
        }
    }

    .asset-manager {
        flex: 1;
    }

    .pure-menu-list {
        text-align: left;

        .pure-menu-item.pure-menu-has-children {
            vertical-align: inherit;

            .pure-menu-link {
                color: @white;
                cursor: pointer;
                padding: 4px 10px 5px;

                &:hover {
                    background-color: @color-bg-1;
                }
            }

            &.pure-menu-active {
                > .pure-menu-link {
                    background-color: @color-bg-1;
                }
            }

            .pure-menu-children {
                width: 100%;

                .pure-menu-item {
                    background-color: @color-bg-1;

                    .pure-menu-link {
                        color: @white;
                    }

                    &:hover,
                    .pure-menu-link:hover {
                        color: @white;
                        background-color: @color-high-1;
                    }
                }
            }
        }
    }

    .tenant-selection-menu.pure-menu-link {
        min-width: 150px;
    }
}

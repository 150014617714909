@import (reference) "../../../../resources/styles/variables";

.meeting-selector {
    background-color: @color-bg-0;

    .row {
        border-bottom: solid 2px @color-bg-1;
        display: flex;
        flex-direction: row;
        min-height: 78px;
    }

    .back {
        width: 80px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .row-name {
        width: 175px;
        cursor: default;
    }

    .multi-enable {
        margin-right: 1px;
        padding-right: 1px;
    }

    .options {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
    }

    .selectable {
        cursor: pointer;

        &:first-child {
            .content {
                border-left: 1px solid transparent;
            }
        }
        &:last-child {
            .content {
                border-right: 1px solid transparent;
            }
        }
    }

    .option {
        flex: 0 0 175px;
        color: @white;
        padding: 15px 0 13px;
        min-height: 78px;

        &.highlight {
            background-color: @color-high-2;
        }

        &.selected {
            background-color: @color-bg-1;
            margin-left: -1px;
            padding-left: 1px;
        }

        .content {
            width: 100%;
            height: 100%;
            border-right: 1px solid @color-bg-1;

            display: flex;
            align-items: center;

            &.center {
                justify-content: center;

                i {
                    margin-right: 10px;
                }
            }

            .subscribed {
                color: @color-high-1;
            }

            .icon {
                flex: 0 0 60px;
                text-align: center;
            }

            .details {
                p {
                    color: @color-text-primary;
                    margin: 0;
                    padding: 0;
                }

                &.status-Unknown,
                &.status-Paying,
                &.status-Abandoned {
                    p {
                        color: @color-text-secondary;
                    }
                }

                &.status-Normal {
                    p.time-to-race {
                        color: @color-high-1;
                    }
                }

                &.status-Closed,
                &.status-Interim {
                }
            }
        }
    }

    .fallback {
        color: @color-text-secondary;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 45px;
    }

    .race-countdown {
        margin: auto;
        font-size: 36px;
    }
}

@import (reference) "../../../../resources/styles/variables.less";

.pre-race-controls {
    flex: 1 1 0;
    display: flex;

    .runners-column {
        flex: 1 1 1130px;

        display: flex;
        flex-direction: column;

        .runners-table {
            flex: 1 1 0;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }

    .spacer {
        height: 60px;
    }
}

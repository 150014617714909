.meeting-selector-venue {
    text-align: center;
}
.graphics-test-button {
    margin-top: 100px;
    margin-left: 10px;
}

.sky-meeting-selector {
    .venue-selector-and-visible {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .venue-selector-and-visible > :first-child {
        margin-right: 10px;
    }
}
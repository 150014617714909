@import (reference) "../../../../resources/styles/variables";
@import (reference) "../../../../components/Forms/FormButton";

@border-width: 1px;
@width: 250px;
@height: 60px;
@options-button-width: 60px;

@dropdown-color: #0083b2;

.component-button {
    .form-button;

    width: @width;
    margin-bottom: 10px;

    &.with-options {
        align-items: stretch;

        .options-button {
            .center-center;

            flex: 0 0 60px;
            height: 100%;
            border-left: 1px solid @color-high-1;

            &.active {
                .settings-icon {
                    stroke: @color-text-primary;
                    fill: @color-text-primary;
                }
            }
        }

        .pure-menu-children {
            top: @height - (2 * @border-width);
            left: @options-button-width - @width;
            width: @width;
            z-index: 10;

            li.pure-menu-item {
                color: @color-text-primary;
                background-color: @dropdown-color;

                display: flex;
                justify-content: start;
                align-items: center;

                &:hover {
                    background-color: @color-bg-2;
                }

                .active {
                }

                .checkbox-container {
                    padding: 10px 15px;
                }
            }

            div.text-inputs {
                padding: 10px 15px;
                background-color: #0083b2;
            }

            input.text-menu-item {
                color: @color-text-primary;
                background-color: @dropdown-color;
                border: 1px solid white;

                display: flex;
                justify-content: start;
                align-items: center;

                padding: 5px;
                width: 100%;

                &:hover {
                    background-color: @color-bg-2;
                }
            }
        }

        &.disabled {
            .options-button {
                cursor: pointer;
                border-left: 1px solid @color-disabled;
            }
        }
    }

    .settings-icon {
        stroke: @color-text-primary;
        fill: @color-text-primary;
    }

    .title {
        padding: 0px 25px;
        position: relative;

        .visibility-icon {
            position: absolute;
            right: 20px;
        }
    }
}

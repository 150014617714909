@import (reference) "../../../../resources/styles/table";
@import (reference) "../../../../resources/styles/dialog";

.error-banner {
    border-color: 1px solid @color-danger;
    background-color: @color-danger;
    padding: 20px;
    margin-bottom: 10px;
}

.busy-spinner-container {
    position: relative;
}

.sponsor-logo-dialog {
    .dialog;

    @dialog-width: 40vw;
    width: @dialog-width;
    max-width: @dialog-width;
    padding: 1.2rem;

    @logo-thumbnail-size: 60px;

    .race-title-bar {
        justify-content: flex-start;
        margin-bottom: 20px;
    }

    .dialog-content {
        display: flex;
        justify-content: space-between;

        & > * {
            flex: 1 1 0;

            &.divider {
                flex: 0;
                margin: 0 5px;
                width: 1px;
                top: 0;
                // border-right: 1px solid @color-bg-1;
            }
        }
    }

    .sponsor-logo-table {
        margin-bottom: 20px;
        margin-right: 5px;

        h2 {
            .section-banner;
            padding: 4px 5px 5px;
            margin: 0;
        }

        .sponsor-logo-table-rows {
            overflow-y: scroll;
            max-height: 470px;

            .sponsor-logo-table-row {
                height: @logo-thumbnail-size;
                margin-bottom: 1px;

                display: flex;
                align-content: center;
                cursor: pointer;

                &.selected {
                    box-shadow: 0 0 10px 0 @color-high-1;
                    background-image: linear-gradient(
                        to right,
                        @color-high-2,
                        @color-high-1
                    );
                }

                .logo-cell {
                    .center-center;
                    flex: 0 0 @logo-thumbnail-size;
                    padding-right: 10px;

                    img {
                        max-height: @logo-thumbnail-size;
                        max-width: @logo-thumbnail-size;
                    }
                }

                span {
                    flex: 1 1 0;

                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                }
            }
        }
    }

    .button-bar {
        & > div {
            display: flex;
            justify-content: flex-start;

            .form-button {
                margin-right: 10px;
            }

            &:last-child .form-button {
                margin-left: 10px;
            }
        }
    }
}

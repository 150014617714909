@import (reference) "resources/styles/variables";
@import (reference) "resources/styles/form";
@import (reference) "resources/styles/dialog";

.asset-list {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.asset-list-item {
    display: flex;
    align-items: center;

    height: 100px;
    padding: 10px;

    font-size: @font-size-m;

    border: 1px solid @color-bg-1;

    &:not(:first-child) {
        border-top: none;
    }
}

.asset-icon {
    height: 100%;
    width: 80px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
    }
}

.asset-name {
    flex: 1;
    padding: 0 20px;
}

.upload-dialog {
    min-width: 800px;
}

.upload-button {
    .button;
    margin-left: 20px;
    margin-top: 10px;
}

.delete-button {
    .button;
}

.asset-list-item .edit-button {
    padding: 20px;
}

.asset-list-item .edit-button * {
    cursor: pointer;
}

@import (reference) "../../../../resources/styles/variables";

.sky-meeting-selector {
    background-color: @color-bg-0;

    .venue-selector {
        margin-left: 5px;
        padding: 10px 0;
        justify-content: center;
    }

    .venue-selector > * {
        margin-bottom: 10px;
    }

    .row {
        display: flex;
        flex-direction: row;
        width: 100%;
        min-height: 78px;

        border-bottom: solid 2px @color-bg-1;
    }

    .col {
        display: flex;
        flex-direction: column;
    }

    .row-name {
        width: 175px;
        cursor: default;
    }

    .back {
        padding: 10px 0;
        width: 185px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .multi-enable {
        padding-top: 10px;
        height: 60px;
        width: 185px;

        display: flex;
        justify-content: center;
        align-items: center;

        border-bottom: solid 2px @color-bg-1;
        border-top: solid 2px @color-bg-1;
    }

    .options {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
    }

    .options-col {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        width: 100%;
        height: 740px;

        overflow-y: auto;
    }

    .selectable {
        cursor: pointer;

        &:first-child {
            .content {
                border-top: 1px solid transparent;
            }
        }
        &:last-child {
            .content {
                border-bottom: 1px solid transparent;
            }
        }
    }

    .race-option {
        border-bottom: 1px solid @color-bg-1;
        border-right: 1px solid @color-bg-1;
    }

    .option {
        flex: 0 0 185px;
        color: @white;
        padding: 15px 0 13px;
        max-height: 78px;

        &.highlight {
            background-color: @color-high-2;
        }

        &.selected {
            background-color: @color-bg-1;
            margin-left: -1px;
            padding-left: 1px;
        }

        .content {
            width: 100%;
            height: 100%;

            display: flex;
            align-items: center;

            &.center {
                justify-content: center;

                i {
                    margin-right: 10px;
                }

                svg {
                    margin-right: 15px;
                }
            }

            .subscribed {
                color: @color-high-1;
            }

            .icon {
                flex: 0 0 60px;
                text-align: center;
            }

            .details {
                margin-left: 15px;

                p {
                    color: @color-text-primary;
                    margin: 0;
                    padding: 0;
                }

                &.status-Unknown,
                &.status-Paying,
                &.status-Abandoned {
                    p {
                        color: @color-text-secondary;
                    }
                }

                &.status-Normal {
                    p.time-to-race {
                        color: @color-high-1;
                    }
                }
            }
        }

        .race-button {
            padding-left: 10px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
        }
    }

    .current-race-button {
        width: 100%;
    }

    .fallback {
        color: @color-text-secondary;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 45px;
    }

    .race-countdown {
        margin: auto;
        font-size: 36px;
    }
}

@import (reference) "../../../../resources/styles/variables";

.compositor {
    cursor: pointer;
    margin: 15px;

    border: 1px solid transparent;
    border-bottom: 0;

    &.has-truck {
    }

    &:hover {
        .compositor-details {
            border: 1px solid @color-high-1;
            border-top: 0;
            background-color: @color-high-1;
        }
    }

    .compositor-details {
        transition: all 0.5s ease-out;

        display: flex;
        padding: 15px;
        border: 1px solid @color-bg-1;
        border-top: 0;

        .compositor-icon {
            flex: 0 0 120px;
            margin-right: 20px;
            .center-center;
        }

        .compositor-description {
            flex: 1 1 0;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
    }
}

@import (reference) "../../resources/styles/variables";

.drop-zone {
    padding: 5px 0;
    display: flex;
    flex-direction: column;

    .target {
        .center-center;
        flex-direction: column;
        cursor: pointer;
        border: 2px dashed @color-bg-3;

        &.target-active {
            border-color: @color-high-1;
        }

        &.target-reject {
            border-color: @color-danger;
        }
    }

    .image-preview {
        @animation-time: 200ms;

        overflow: hidden;
        position: relative;

        max-width: 80%;

        img {
            border: 2px dashed @color-bg-3;
            width: 100%;
            height: 100%;
        }

        .image-preview-controls {
            position: absolute;
            bottom: 10px;
            right: 10px;

            .icon {
                padding: 10px;
                background: rgba(50, 51, 66, 0.7);
                border: 1px solid @color-text-primary;
                border-radius: 50px;
                cursor: pointer;
            }
        }
    }
}

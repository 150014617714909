@import (reference) "../../../../resources/styles/variables";

.meeting-selector {
    .option {
        .content {
            &.center {
                svg {
                    margin-right: 15px;
                }
            }
        }
    }

    .back {
        height: 78px;
    }
}

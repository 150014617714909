svg.lv-icon-tick {
    g path {
        fill-rule: evenodd;
    }
}

svg.lv-icon-cross {
    path {
        line-height: normal;
        text-indent: 0;
        text-transform: none;
        block-progression: tb;
        isolation: auto;
        mix-blend-mode: normal;

        font-weight: 400;
    }
}

@import (reference) "../../../resources/styles/variables.less";

header {
    flex: 0 0 40px;
    padding-left: 10px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: @white;
    background-image: linear-gradient(to right, @color-high-2, @color-high-1);

    .font-size-l;

    & > * {
        flex: 1 1 0;
        padding: 4px 0 5px;
    }

    .pure-menu-horizontal {
        width: auto;
        padding: 0;
    }

    .pure-menu-list {
        text-align: right;

        .pure-menu-item.pure-menu-has-children {
            vertical-align: inherit;

            .pure-menu-link {
                color: @white;
                cursor: pointer;
                padding: 4px 10px 5px;

                &:hover {
                    background-color: @color-high-2;
                }
            }

            &.pure-menu-active {
                > .pure-menu-link {
                    background-color: @color-high-2;
                }
            }

            .pure-menu-children {
                width: 100%;

                .pure-menu-item {
                    background-color: @color-high-2;

                    .pure-menu-link {
                        color: @color-bg-2;
                    }

                    &:hover,
                    .pure-menu-link:hover {
                        color: @white;
                        background-color: @color-high-1;
                    }
                }

                hr {
                    height: 3px;
                    background-color: @white;
                    margin-block: 0 !important;
                }
            }
        }
    }

    .user-menu.pure-menu-link {
        min-width: 250px;
    }
}

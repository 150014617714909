@import (reference) "../../../../resources/styles/variables";

@pulse-time: 1.5s;

.heartbeats {
    .center-center;
    padding: 0 30px;

    .heartbeat-container {
        position: relative;
        display: flex;

        .pulse {
            position: absolute;
            top: 0;
            left: 5px;
            border: 1px solid transparent;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            z-index: 1;

            background-color: transparent;
            animation: pulsate @pulse-time linear;
            animation-iteration-count: 1;
        }

        @-webkit-keyframes pulsate {
            0% {
                -webkit-transform: scale(1, 1);
                opacity: 0;
            }
            50% {
                opacity: 1;
            }
            100% {
                -webkit-transform: scale(1.5, 1.5);
                opacity: 0;
            }
        }

        .circle {
            width: 20px;
            height: 20px;
            border-radius: 10px;
            margin: 0 5px;
            cursor: pointer;
            z-index: 2;
        }

        .heartbeat-state(@color) {
            .circle {
                background-color: @color;
            }

            .pulse {
                border-color: @color;
            }
        }

        &.normal {
            .heartbeat-state(#008543);
        }

        &.warning {
            .heartbeat-state(#dd6);
        }

        &.error {
            .heartbeat-state(#f66);
        }

        &.fatal {
            .heartbeat-state(#980013);
        }

        .tooltip {
            position: absolute;
            top: 80%;
            left: 80%;
            width: max-content;

            padding: 10px;

            background-color: fade(@color-bg-1, 60%);

            display: none;

            p {
                margin: 0;
            }
        }

        &:hover .tooltip {
            display: initial;
        }
    }
}

@import (reference) "../../../resources/styles/variables";

.module {
    cursor: pointer;
    margin: 15px;

    border: 1px solid transparent;
    border-bottom: 0;

    &.active {
        .module-icon {
            background-color: @color-high-2;
            border-top: 0;
        }
    }

    &:hover {
        .module-icon {
            border: 1px solid @color-high-1;
            border-top: 0;
            background-color: @color-high-1;
        }
    }

    .module-icon {
        transition: all 0.5s ease-out;

        padding: 15px;
        border: 1px solid @color-bg-1;
        border-top: 0;
        .center-center;

        img {
            max-height: 80px;
        }
    }
}

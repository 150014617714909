@import (reference) "../../../../resources/styles/variables";
@import (reference) "../../../../components/AssetManagement/AssetList.less";

.meet-title-bar {
    display: flex;
    justify-content: center;

    .venue {
        font-size: 32px;
        font-weight: normal;
        margin: 0;
    }

    .meet-container {
        padding: 10px;
        border: 1px solid;
    }
}

.race-controls {
    flex: 1 1 0;
    display: flex;
    margin: 10px;

    .race-table {
        flex: 1 1 1130px;

        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;

        thead {
            tr {
                .id-cell {
                    min-width: 55px;
                    padding-left: 5px;
                }

                .time-cell {
                    min-width: 80px;
                    text-align: center;
                }

                .name-cell {
                    width: 500px;
                }

                .state-cell {
                    min-width: 80px;
                    text-align: center;
                }

                .distance-cell {
                    min-width: 80px;
                    padding-right: 5px;
                    text-align: center;
                }
            }
        }

        tbody {
            tr {
                border-radius: 0.3rem;

                &.raced {
                    color: @color-text-secondary;
                    text-decoration: line-through;
                }

                .id-cell {
                    text-align: center;
                    min-width: 55px;
                }

                .time-cell {
                    min-width: 80px;
                    text-align: center;
                }

                .name-cell {
                    width: 500px;
                }

                .state-cell {
                    min-width: 80px;
                    text-align: center;
                }

                .distance-cell {
                    min-width: 80px;
                    padding-right: 5px;
                    text-align: center;
                }
            }
        }
    }
}

.current-sponsor-preview {
    height: 45px;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    font-size: @font-size-s;

    img {
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
    }
}

.sponsor-modal {
    height: 80vh;
    width: 40vw;
    padding: 50px;
    overflow: scroll;

    .search-textbox {
        width: 80%;
        color: black;
        padding: 20px;
    }

    .select-button {
        .button;
    }

    .upload-button {
        .button;
    }
}

@import (reference) "../../../resources/styles/dialog";

.timing-controls {
    flex: 1 1 0;

    @column-header-height: 100px;
    @left-column-width: 900px;
    @button-column-padding: 10px;
    @button-column-width: 150px;

    display: flex;
    flex-direction: column;

    .three-column-container {
        display: flex;
        align-items: flex-start;

        .column {
            display: flex;
            flex-direction: column;

            .column-header {
                height: @column-header-height;
                background-color: @color-bg-0;

                display: flex;
                justify-content: left;
                align-items: center;
                padding-left: 160px;
            }

            &:first-child {
                flex: 1 1 @left-column-width;

                .button-column {
                    flex: 0 0 @button-column-width + @button-column-padding * 2;
                    padding: 0 @button-column-padding;
                }
            }

            .clock {
                height: 60px;
                margin-bottom: 10px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                font-size: @font-size-l;
            }

            &:nth-child(2) {
                margin-top: 40px;
                margin-right: 10px;
                box-shadow: 0 10px 20px 0 rgba(50, 51, 66, 0.5);
                background-blend-mode: multiply;

                display: flex;
                flex-direction: column;

                .button-column {
                    flex: 0 0 @button-column-width + @button-column-padding;
                    padding-left: @button-column-padding;
                }
            }

            .track-map-container {
                width: 900px;
                height: 700px;
            }

            .dash-player-container {
                width: 900px;
            }
        }
    }

    .environment-warning {
        width: 100%;

        display: flex;
        align-items: center;
        margin: 10px 0 10px 70px;

        font-size: @font-size-m;
        color: @color-interim;
    }
}

@kill-button-size: 120px;

.kill-button {
    .font-size-s;
    text-transform: uppercase;

    z-index: 100;
    position: fixed;
    bottom: 10px;
    right: 10px;

    width: @kill-button-size;
    height: @kill-button-size;
    border-radius: (@kill-button-size / 2);

    color: @color-text-primary;
    background: @color-danger;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.75);
    transition: all 0.1s ease-out;

    &:active {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
    }
}

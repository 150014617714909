.dash-player {
    width: 100%;
    margin: 10px;
}
.dash-player-url {
    margin-top: 20px;
}
.dash-player-url-copy {
    cursor: pointer;
}
.dash-player-url-copy:hover {
    color: grey;
}
.dash-player-url-copy:active {
    transform: translateY(1px);
}

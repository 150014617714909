@import "~purecss/build/pure.css";
@import "~@fontsource/abel";

@import "./variables";
@import "./table";

body {
    font-family: "Abel", sans-serif;

    background-color: @color-bg-0;
    color: @color-text-primary;
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }
}

html,
body,
#app {
    height: 100vh;
}

h1 {
    .section-banner;

    font-weight: normal;
    padding: 10px;
    margin: 0;
    text-align: center;
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
}

&::-webkit-scrollbar {
    width: 2px;
    background-color: @color-bg-0;
}

&::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px @color-bg-0;
    background-color: @color-bg-2;
}

&::-webkit-scrollbar-track {
    border-radius: 0;
    box-shadow: inset 0 0 6px @color-bg-0;
    background-color: @color-bg-1;
}

// ----- Pure CSS ------------------------------------------------------------------------------------------------------

.pure-g [class*="pure-u"] {
    font-family: "Abel", sans-serif;
}

.pure-form {
    input[type="text"],
    input[type="password"],
    select,
    textarea {
        background-color: transparent;

        &,
        &:focus {
            box-shadow: none;
            border-radius: 0;
        }
    }

    input[type="text"],
    input[type="password"] {
        padding: 0.5em 0;
        text-transform: uppercase;

        &,
        &:focus {
            border: none;
            border-bottom: 1px solid @color-high-1;
        }
    }

    textarea {
        padding: 0.5em;
        text-transform: uppercase;

        &,
        &:focus {
            border: 1px solid @color-high-1;
        }
    }

    select {
        border: 1px solid @color-high-1;
    }
}

.pure-component-button {
    color: @color-text-primary;
    border: 1px solid @color-high-1;
    border-radius: 0;
    text-transform: uppercase;
    cursor: pointer;
    background: transparent;

    &.disabled {
        cursor: default;
        color: @color-text-secondary;
        border-color: @color-text-secondary;
    }

    &:active:not(.disabled) {
        box-shadow: 0 0 10px 0 @color-high-1;
        background-color: @color-high-1;
    }

    &:focus {
        background-color: @color-high-1;
    }
}

.pure-dropdown {
    border: 1px solid @color-high-1;
    cursor: pointer;
    text-transform: uppercase;

    option {
        padding: 3px 8px;
        background-color: @color-bg-1;

        &:hover {
            background-color: @color-high-2;
        }
    }
}

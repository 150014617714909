@import (reference) "../../../../resources/styles/variables.less";

.left-column {
    flex: 1 1 0;
    display: flex;

    .runners-column {
        flex: 1 1 1130px;

        display: flex;
        flex-direction: column;

        .runners-table {
            flex: 1 1 0;
        }
    }

    .spacer {
        height: 60px;
    }
}

@import (reference) "../../../../../resources/styles/variables.less";

section.manual-super {
    flex: 0 0 185px;

    form {
        padding: 0 10px;

        .include-silk {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 15px;
            text-transform: uppercase;
        }

        fieldset:last-child {
            padding-bottom: 0;
        }

        .component-button {
            width: auto;
        }
    }
}

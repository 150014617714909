@import (reference) "../../resources/styles/colours";

.app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.shortcutkeys {
    position: absolute;
    bottom: 0;
    padding: 0.5rem;
    opacity: 0;
    background: rgba(255, 255, 255, 0.3);
}

.shortcutkeysvisible {
    opacity: 0.5;
    transition: opacity 0.3s ease-in;
}

@import (reference) "../../../../../resources/styles/variables";

@box-size: 60px;

div.running-number {
    .font-size-xxl;
    .center-center;
    width: @box-size;
    height: @box-size;
    border: 2px solid @color-high-1;
    position: relative;
    margin-right: 20px;

    &.invalid,
    &.duplicate {
        border-color: @color-danger;
    }

    &.scratched {
        border-color: @color-disabled;
    }

    &.interim {
        border-color: @color-interim;
    }

    &.final {
        border-color: @color-final-dark;
    }

    div.position {
        .font-size-xxs;
        position: absolute;
        padding: 1px 0 1px 3px;
        top: 18px;
        left: -14px;
        z-index: 10;
        background-color: @color-bg-0;
    }

    input {
        background: transparent;
        border: none;
        outline: none;
        text-align: center;
        max-width: @box-size - 20px;

        &:focus,
        &:active {
            border: none;
            outline: none;
        }
    }

    &.editing {
        background-color: @color-high-1;

        div.position {
            background-color: @color-high-1;
        }
    }

    &.text-selected {
        span {
            background-color: orange;
        }
    }
}

.update-running-numbers {
    opacity: 0;
    width: 5px;
    transition: all 0.5s ease-in-out;
    pointer-events: none;

    &.visible {
        pointer-events: auto;
        opacity: 1;
        width: 140px;
    }
}

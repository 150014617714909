@import (reference) "../../resources/styles/variables";

@checkbox-size: 24px;

.checkbox-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    cursor: pointer;

    svg {
        border: 1px solid @text-color;
        padding: 2px;

        margin-right: (@checkbox-size / 2);

        path {
            fill: @text-color;
        }
    }

    &.checked {
        svg {
            background-color: @color-high-1;

            path {
                fill: @text-color;
            }
        }
    }

    .checkbox-unchecked {
        width: @checkbox-size;
        height: @checkbox-size;
        margin-right: (@checkbox-size / 2);
        border: 1px solid @color-text-primary;
        background-color: transparent;
    }

    &.checkbox-disabled {
        color: @color-disabled;

        svg {
            border: 1px solid @color-disabled;
            background-color: @color-disabled;

            path {
                fill: @color-high-2;
            }
        }

        .checkbox {
            border-color: @color-disabled;
        }
    }
}

@import (reference) "../../../../resources/styles/variables";

.start-truck-session {
    padding-top: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .inner {
        color: @color-text-primary;
        padding: 20px 100px;

        &:hover {
            background-color: @color-high-1;
            cursor: pointer;
            transition: all 0.25s ease-in;
        }
    }

    .cancel {
        display: flex;
        justify-content: center;
    }

    .icons {
        display: flex;
        justify-content: center;
    }

    .message {
        .font-size-l;

        display: flex;
        justify-content: center;
        text-transform: uppercase;
    }

    .plug {
        position: relative;
        left: 0;
        right: 0;
        transform: rotate(-90deg);
        margin-left: 2rem;
    }

    .plugging {
        animation: plugging-in-animation 2s infinite;
    }

    .plugMode {
        transform: rotate(90deg);
        animation: initial-click-animation 2s infinite;
    }

    @keyframes initial-click-animation {
        0% {
            left: 1rem;
        }
        80% {
            left: 6rem;
        }
        85% {
            left: -2rem;
        }
        95% {
            left: 3rem;
        }
        100% {
            left: 1rem;
        }
    }

    @keyframes plugging-in-animation {
        0% {
            left: 0;
            color: white;
        }
        5% {
            left: 1rem;
        }
        10% {
            left: -3rem;
        }
        15% {
            left: -2rem;
        }
        20% {
            left: 0;
        }
        25% {
            left: 0.5rem;
        }
        30% {
            left: 1rem;
        }
        35% {
            left: 0.5rem;
            color: orange;
        }
        40% {
            left: 0;
        }
        70% {
            color: white;
        }
        100% {
            left: 0;
            color: white;
        }
    }
}

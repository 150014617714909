@import (reference) "resources/styles/variables";

.button {
    .font-size-s;

    color: inherit;
    background-color: transparent;
    border: none;
    border: 1px solid @color-high-1;
    padding: 0.8em;
    cursor: pointer;

    &:not(:disabled) {
        &:focus,
        &:hover {
            color: @color-high-1;
        }

        &:active {
            background-color: @color-high-1;
            color: white;
        }
    }

    &:disabled {
        cursor: default;
        color: @color-text-secondary;
        border-color: @color-text-secondary;
    }
}

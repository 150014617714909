@import (reference) "../../../../resources/styles/dialog";

.venue-images-dialog {
    .dialog;
    @dialog-width: 1200px;
    @dialog-height: 800px;

    width: @dialog-width;
    max-width: @dialog-width;
    min-height: @dialog-height;

    @image-thumbnail-size: 125px;

    .venue-images-existing {
        flex: 0 0 420px;
        margin-right: 10px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        div.reorder {
            flex: 1 1 0;
            margin-bottom: 10px;
            overflow-y: scroll;

            .image-row {
                padding: 5px 0;
                cursor: pointer;

                &:nth-child(even) {
                    // background: linear-gradient(to bottom, @color-bg-1, @color-bg-3);
                }

                &:hover:not(.selected) {
                    background: @color-bg-2;
                    box-shadow: none;
                }

                &.selected {
                    box-shadow: 0 0 10px 0 @color-high-1;
                    background-image: linear-gradient(
                        to right,
                        @color-high-2,
                        @color-high-1
                    );
                }
            }

            img {
                display: block;
                max-height: @image-thumbnail-size;

                &.placeholder {
                    opacity: 0.3;
                }
            }
        }
    }

    .venue-image-uploader {
        flex: 1 1 0;

        height: @dialog-height;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .drop-zone {
            .center-center;
        }

        .dialog-action-bar {
            justify-content: flex-end;
        }
    }
}

@import (reference) "resources/styles/variables";
@import (reference) "resources/styles/dialog";
@import (reference) "resources/styles/form";

.single-image-page {
    padding: 30px 20%;
    height: 80%;

    .button {
        .button;
        width: 200px;
        margin-bottom: 20px;
    }

    .fullframe-image-table {
        @logo-thumbnail-size: 60px;

        margin-bottom: 20px;
        margin-right: 5px;

        h2 {
            .section-banner;
            padding: 4px 5px 5px;
            margin: 0;
        }
    }

    .image-list {
        height: 90%;
        overflow-y: scroll;
        padding: 20px 5%;

        .image-container {
            display: flex;
            flex-direction: row;
            align-items: center;
    
            padding: 10px;
            margin: 10px 0;
            height: 180px;
            width: 100%;
    
            border: 1px solid @color-bg-2;
    
            .image-delete {
                .font-size-max;
                margin: 0 40px;
                cursor: pointer;
            }
    
            .image-delete:hover {
                color: @color-danger;
            }
    
            img {
                height: 100%;
            }
    
            .image-name {
                .font-size-l;
                margin-left: 70px;
            }
    
            .single-image-button {
                margin-left: auto;
                margin-right: 40px;
            }
        }
    }
}
@import (reference) "../../resources/styles/variables";

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.icond {
    color: @text-color;
    font-size: 1.25rem;
    text-decoration: none;
    padding: 0.5rem;
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    &:hover {
        background-color: #6a8eae;
        color: #9bd1e5;
        transition: background 0.3s ease-in, color 0.3s ease-in;
    }
}

.reconnecting {
    .center-center;
    flex-direction: column;
}

@import (reference) "../../../../resources/styles/variables";

.table.exotics-table {
    thead {
        tr {
            th {
                .text-center;
            }
        }
    }

    tbody {
        td:first-child {
            width: 33%;
        }

        td.exotic-numbers-cell {
            .text-center;
            width: 33%;
        }
    }
}

@import (reference) "../../../../resources/styles/variables";

.runners-table {
    thead {
        tr {
            th {
                &.silk-cell {
                    .text-center;
                    padding: 0 8px 0 0;
                }

                &.price-cell {
                    .text-right;
                    padding-right: 5px;
                }

                &.interim {
                    background-color: @color-interim;
                }

                &.final {
                    background-color: @color-final-dark;
                }
            }

            &.dead-heat {
                background-color: @color-high-2;
            }
        }
    }

    tbody {
        tr {
            border-radius: 0.3rem;

            &.scratched {
                color: @color-text-secondary;
                text-decoration: line-through;
            }

            .horse-number-cell {
                .font-size-xxl;
                width: 50px;
                text-align: center;
            }

            .silk-cell {
                max-width: 57px;
                height: 45px;
                text-align: center;

                img,
                .silk-tab {
                    max-height: 40px;
                }

                div > svg {
                    max-height: 40px;
                    max-width: 40px;
                    padding-right: 5px;
                }

                &.scratched {
                    text-decoration: line-through;
                }
            }

            .photo-finish-cell {
                text-transform: uppercase;
            }
        }
    }
}

@keyframes amountDeltaUpAnimation {
    0% {
        color: @color-text-primary;
    }
    20% {
        color: @color-ok;
    }
    100% {
        color: @color-text-primary;
    }
}

@keyframes amountDeltaDownAnimation {
    0% {
        color: @color-text-primary;
    }
    20% {
        color: #f66;
    }
    100% {
        color: @color-text-primary;
    }
}

.amount-delta {
    animation-duration: 1.6s;
    animation-timing-function: linear;
    animation-iteration-count: 3;
    animation-play-state: running;
}

.amount-delta-up {
    .amount-delta;
    animation-name: amountDeltaUpAnimation;
}

.amount-delta-down {
    .amount-delta;
    animation-name: amountDeltaDownAnimation;
}

.barrier-toggle {
    background-color: transparent;
    border: 1px solid @color-high-1;
    padding: 3px;
    margin: 0 10px;
    cursor: pointer;
}

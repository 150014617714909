@import (reference) "../../../../resources/styles/variables";
@import (reference) "../../../../components/AssetManagement/AssetList.less";

.triples-runners-table {
    thead {
        tr {
            th {
                &.silk-cell {
                    .text-center;
                    padding: 0 8px 0 0;
                }
            }
        }
    }

    tbody {
        tr {
            border-radius: 0.3rem;

            &.scratched > td {
                color: @color-text-secondary;
                text-decoration: line-through;

                &.horse-number-cell {
                    filter: grayscale(100%) opacity(60%);
                    text-decoration: none;
                }
            }

            .horse-number-cell {
                .font-size-xxl;
                display: flex;
                align-items: center;
                justify-content: center;

                .horse-chiclet {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .silk-cell {
                max-width: 57px;
                height: 45px;
                text-align: center;
                position: relative;

                .large-silk {
                    z-index: 3;
                    height: 220px;
                    width: 200px;
                    position: absolute;
                    top: -100px;
                    left: 80px;
                    background: #aaabbc;
                    border: 2px solid black;

                    img {
                        max-height: 180px;
                        position: absolute;
                        top: -20px;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto;
                    }

                    p {
                        color: black;
                        position: relative;
                        z-index: 4;
                        top: 180px;
                    }
                }

                .selector {
                    position: absolute;
                    padding: 7px;
                    size: 10pt;
                    right: 0;
                    top: 0;
                    font-size: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                }

                img,
                .silk-tab {
                    max-height: 40px;
                    &.scratched {
                        filter: grayscale(100%) opacity(60%);
                    }
                }

                div > svg {
                    max-height: 40px;
                    max-width: 40px;
                    padding-right: 5px;
                }

                &.scratched {
                    text-decoration: line-through;
                }
            }
        }
    }
}

.silk-modal {
    height: 80vh;
    width: 40vw;
    padding: 50px;
    overflow: scroll;

    .search-textbox {
        width: 80%;
        color: black;
        padding: 20px;
    }

    .select-button {
        .button;
    }

    .upload-button {
        .button;
    }

    h2 {
        .font-size-xl;
        text-align: center;
    }

    .chiclet-color-selector-label {
        .font-size-xs;
        padding-right: 10px;
    }
}

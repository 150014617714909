.track-map {
    width: 100%;
    height: 100%;
    margin: 10px;
    /* create a new stacking context so that map isn't placed over anything else */
    z-index: 0;
}

.track-map-chiclet {
    /* just in case we don't have chiclet colours for whatever reason */
    background-color: white;
    color: black;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.8em;
    height: 30px;
    width: 30px;
}

.leaflet-control-attribution {
    background: white;
}

.follow-leader-checkbox {
    float: left;
    padding: 15px;
}

@import (reference) "../../resources/styles/variables";

.login-header {
    display: flex;
    justify-content: center;
}

.login-form-container {
    height: 100vh;

    display: flex;
    justify-content: center;
    padding-top: 30px;

    .uppercase {
        text-transform: uppercase;
    }

    .login-form {
        display: block;

        label {
            .font-size-xxs;
        }

        input[type="text"],
        input[type="password"] {
            width: 400px;
            text-transform: none;
        }

        .login-button {
            margin-top: 10px;
        }
    }
}

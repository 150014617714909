@import (reference) "../../resources/styles/variables";

.busy-spinner-host {
    position: relative;
    height: 100%;
}

.busy-spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .center-center;
    z-index: 15;
    background-color: rgba(50, 51, 66, 0.4);
}

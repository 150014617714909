@import (reference) "resources/styles/variables";

.modal {
    color: @color-text-primary;
    background-color: @color-bg-0;
    border: none;
}

.modal::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-close-icon {
    float: right;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5em;
}

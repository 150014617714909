@import (reference) "../../../resources/styles/variables.less";

.connected-truck-header {
    flex: 0 0 40px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: @white;
    background-color: @color-bg-1;
    padding: 0 15px 0 10px;

    .font-size-s;

    > * {
        flex: 1 1 0;
        padding: 8px 0 6px;
    }

    .live-components {
        .text-center;
    }

    .latency {
        .text-right;
    }
}

@import (reference) "../../../resources/styles/dialog";

.racing-controls {
    flex: 1 1 0;

    @column-header-height: 100px;
    @left-column-width: 1090px;
    @button-column-padding: 10px;
    @button-column-width: 200px;

    @right-column-width: 1920px - @left-column-width;
    @results-column-width: @right-column-width - @button-column-width;

    display: flex;
    flex-direction: column;

    .two-column-container {
        flex: 1 1 0;

        display: flex;

        .column {
            display: flex;
            flex-direction: column;

            .column-header {
                height: @column-header-height;
                background-color: @color-bg-0;

                display: flex;
                justify-content: left;
                align-items: center;
                padding-left: 160px;
            }

            &:first-child {
                flex: 1 1 @left-column-width;

                .button-column {
                    flex: 0 0 @button-column-width + @button-column-padding * 2;
                    padding: 0 @button-column-padding;
                }
            }

            &:last-child {
                flex: 1 1 @right-column-width;
                box-shadow: 0 10px 20px 0 rgba(50, 51, 66, 0.5);
                background-blend-mode: multiply;

                display: flex;
                flex-direction: column;

                .button-column {
                    flex: 0 0 @button-column-width + @button-column-padding;
                    padding-left: @button-column-padding;
                }
            }
        }
    }

    .waiting-for-data {
        width: 100%;

        .banner {
            padding: 2rem;
            text-align: center;
            background-color: #444;
        }
    }

    .post-race-controls {
        flex: 1 1 0;
        display: flex;
        background-color: @color-bg-0;

        .results-column {
            flex: 1 1 @results-column-width;
        }
    }
}

@kill-button-size: 120px;

.kill-button {
    .font-size-s;
    text-transform: uppercase;

    z-index: 100;
    position: fixed;
    bottom: 10px;
    right: 10px;

    width: @kill-button-size;
    height: @kill-button-size;
    border-radius: (@kill-button-size / 2);

    color: @color-text-primary;
    background: @color-danger;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.75);
    transition: all 0.1s ease-out;

    &:active {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
    }
}

.shortcut-key-dialog {
    .dialog;

    @dialog-width: 350px;
    @dialog-height: 400px;

    width: @dialog-width;
    max-width: @dialog-width;
    height: @dialog-height;
    max-height: @dialog-height;

    table {
        flex: 1 1 0;
    }

    .dialog-action-bar {
        margin: 15px;
        justify-content: flex-end;
    }
}

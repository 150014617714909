@import (reference) "../../../../resources/styles/variables";
@import (reference) "../../../../components/Forms/FormButton";

@border-width: 1px;
@width: 100%;
@height: 100%;

@dropdown-color: #0083b2;

.multi-component-button {
    .form-button;

    width: @width;
    height: @height;
    margin-bottom: 10px;

    .title {
        padding: 0px 25px;
        position: relative;
        text-align: center;
        font-size: 16px;

        .visibility-icon {
            position: absolute;
            right: 20px;
        }
    }
}

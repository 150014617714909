@import (reference) "./variables";

.dialog {
    overflow: hidden;

    padding: 0;

    .dialog-title-bar {
        .section-banner;
        padding: 14px;
        flex: 0 0 54px;
    }

    .dialog-content {
        flex: 1 1 0;
        padding: 1.2em;
        display: flex;
    }

    .dialog-action-bar {
        flex: 0 0 60px;
        display: flex;

        .form-button {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
